<template>
  <div class="chkbox">
    <input type="checkbox" :checked="checked" :disabled="disabled" />
    <span class="check"></span>
    <label :class="disabled === true ? 'default' : 'pointer'">{{
      label
    }}</label>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.chkbox {
  display: flex;
  align-items: center;
  user-select: none;
  label.default {
    cursor: default;
  }
  label {
    /* position: absolute; */
    z-index: 10;
    padding-left: 10px;
    cursor: pointer;
    // added to make alignments in the language table
    max-width: 190px;
    white-space: pre-wrap;
    word-break: break-word;
    vertical-align: middle;
  }
  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    &:checked ~ .check {
      border-color: #0014ff;
      box-shadow: 0px 0px 0px 15px #0014ff inset;
      &::after {
        opacity: 1;
        transform: scale(1.8);
        z-index: auto;
      }
    }
    &:disabled ~ .check {
      opacity: 0.7;
      border-color: #d5d5d5;
      box-shadow: 0px 0px 0px 15px #d5d5d5 inset;
    }
  }
  .check {
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
    border: 2px solid #0014ff;
    box-shadow: 0px 0px 0px 0px #0014ff inset;
    transition: all 0.15s cubic-bezier(0, 1.05, 0.72, 1.07);
    padding-left: 1px;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 4;
      position: absolute;
      transform: scale(0);
      background-size: 45%;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjgwNSIgaGVpZ2h0PSI2LjEzOCIgdmlld0JveD0iMCAwIDcuODA1IDYuMTM4Ij4NCiAgPGcgaWQ9ImNoZWNrbWFyayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4yNSAwLjE1KSI+DQogICAgPHBhdGggaWQ9IlBhdGhfMSIgZGF0YS1uYW1lPSJQYXRoIDEiIGQ9Ik0yLjEwOCw1LjczOGEuNTMuNTMsMCwwLDEtLjM0My0uMTQ3TC4xNDcsNC4wNzFhLjQ3NC40NzQsMCwwLDEsMC0uNjg2LjQ3NC40NzQsMCwwLDEsLjY4NiwwTDIuMTA4LDQuNjExLDYuNDcyLjI0N2EuNDc0LjQ3NCwwLDAsMSwuNjg2LDAsLjQ3NC40NzQsMCwwLDEsMCwuNjg2TDIuNDUxLDUuNTkxQS40NDUuNDQ1LDAsMCwxLDIuMTA4LDUuNzM4WiIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjAuNSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==");
      background-repeat: no-repeat;
      background-position: center;
      transition-delay: 0.2s !important;
      transition: all 0.25s cubic-bezier(0, 1.05, 0.72, 1.07);
    }
  }
}

.autocomplete {
  .chkbox {
    label {
      font-size: 1rem;
      font-weight: normal;
      margin-top: 3px;
      color: #444;
      padding-left: 5px;
    }
  }
}

.modal-body {
  .autocomplete {
    .chkbox {
      label {
        font-size: 1rem;
        font-weight: normal;
        margin-top: 0px;
        color: #444;
      }
    }
  }
}

.modal-body {
  .autocomplete {
    .chkbox {
      label {
        font-size: 1rem;
        font-weight: normal;
        margin-top: 0px;
        color: #444;
      }
    }
  }
}
*::-ms-backdrop,
.chkbox label {
  padding-left: 7px;
  margin-top: -9px !important;
}
*::-ms-backdrop,
.chkbox .check {
  display: block;
}
.filters__item,
table {
  .chkbox {
    .check {
      border: 1px solid #9ac3ea;
    }
  }
}
</style>
