var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chkbox" }, [
    _c("input", {
      attrs: { type: "checkbox", disabled: _vm.disabled },
      domProps: { checked: _vm.checked }
    }),
    _c("span", { staticClass: "check" }),
    _c("label", { class: _vm.disabled === true ? "default" : "pointer" }, [
      _vm._v(_vm._s(_vm.label))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }